body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.todo-bg {
  background: #d4f1fd;
}

.todo-bg:hover,
.todo-bg:focus {
  box-shadow: 0 2px 5px 0px #93d0ee;
}

/* The container */
.todo-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  margin-top: 1.5rem;
}

/* Hide the browser's default checkbox */
.todo-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 2px;
  border: 1px solid #aaa;
}

/* On mouse-over, add a grey background color */
.todo-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.todo-container input:checked ~ .checkmark {
  background-color: #2196F3;
  border: none;
}

/* When the checkbox is disabled, add a gray background */
.todo-container input:disabled ~ .checkmark {
  background-color: #aaa;
  border: none;
  cursor: auto;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.todo-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.todo-container .checkmark:after {
  left: 10px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* ITEM DETAILS */
.item-details a {
  font-size: 0.85rem;
  opacity: 0.9;
  display: inline-block;
}

.item-details h2 {
  font-size: 1.25rem;
}

/* PAGINATION */
#pagination .disabled {
  cursor: not-allowed;
  pointer-events: none;
}

#pagination .page-link {
  cursor: pointer;
}

/* COMPLETED */
.completed-heading {
  text-decoration: line-through;
}

.completed-bg {
  background-color: #e8e8e8;
}

.completed-bg .completed-link {
  font-size: 0.85rem;
  opacity: 0.9;
  color: #666;
  margin-bottom: 0;
}

/* TABS */

#suggestionTab.nav-tabs {
  background-color: #fff;
  border-radius: 0.5rem;
  border: 1px solid #0d6efd;
}

#suggestionTab .nav-link {
  width: 100%;
  border: 1px solid transparent;
  border-radius: 0.5rem;
}

#suggestionTab .nav-link.active  {
  color: #fff;
  background-color: #0d6efd;
  border-radius: 0.5rem;
  border: 1px solid #fff;
  width: 100%;
}

/* search bar */
.btn-search {
  position: absolute;
  right: 0;
  border: 1px solid #dee2e6;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0 3rem 3rem 0;
  background-color: #eee;
}

.btn-search:hover, .btn-search:focus  {
  background-color: #ddd !important;
}

.btn-search:active  {
  background-color: #eee !important;
  border: 1px solid #dee2e6 !important;
}

.btn-close-search {
  position: absolute;
  right: 64px;
  border-radius: 3rem;
}

.btn-close-search:hover, .btn-close-search:focus {
  background-color: #eee;
  border: 1px solid #eee;
}

.btn-close-search:active {
  background-color: #eee;
  border: 1px solid #eee !important;
}

.search-input {
  border-radius: 3rem;
}

.search-input::placeholder {
  color: #aaa;
  font-weight: normal;
}

.text-time {
  font-size: 0.75rem;
  margin-bottom: 0;
  text-align: right;
  color: #666;
}

.text-time a {
  font-size: 0.75rem;
}

.logo {
  max-width: 100px;
  margin: auto;
  display: block;
}

.sb-logo {
  max-width: 48px;
}

.main-nav {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: #fff;
  border-bottom: 1px solid #cdcdcd;
  box-shadow: 0 0 20px 10px #ddd;
  position: fixed;
  z-index: 1;
}

.suggestion-list {
  background-color: #8ad8f6;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 0.5rem;
  margin-top: 100px !important;
}

@media screen and (max-width: 767px) {
  .suggestion-list {
    margin-top: 170px !important;
  }
}

.login-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-detail {
  background: #8ad8f6;
  padding: 2rem 2rem 3rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 20px 5px #ddd;
}

.not-found {
  width: 100vw;
  height: 100vh;
  display: grid;
  text-align: center;
  background-color: #f7ddc1;
}

.not-found-content {
  place-self: center;
}
/* image css */
.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-container img {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.image-container img:hover {
  transform: scale(1.1) ;
}

.image-container.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px; /* Add padding as needed */
}

.image-container.fullscreen img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
